<template>
  <div class="relative w-full rounded-lg bg-white lg:rounded-[24px]">
    <ClientOnly fallback-tag="span">
      <transition
        name="fade"
        mode="out-in"
      >
        <div v-if="true">
          <div class="flex items-start justify-center transition-all">
            <div
              v-show="dataForLongStore.step_1.product_summ <= 50000"
              class="absolute flex items-start justify-center rounded-b-[8px] bg-dnd_light_purple px-2 py-1 font-manrope text-xs font-semibold xl:text-base"
            >
              <p>Бесплатно</p>
            </div>
          </div>
          <div class="flex h-[372px] flex-col px-4 py-3 lg:h-[490px] xl:px-6">
            <div class="flex flex-col pt-3 lg:pt-6">
              <div
                v-if="!dataForLongStore.isCalcLoading && calcData.summs"
                class="space-y-4 lg:space-y-6"
              >
                <UiSliderCard
                  v-model="dataForLongStore.step_1.product_summ"
                  class="w-full font-semibold"
                  label="Выберите сумму"
                  :data="calcData.summs"
                  :data-borders="[
                    `${templateRangeSummsTuple[0]} ₽`,
                    `${templateRangeSummsTuple[1]} ₽`,
                  ]"
                  :value-label="templateSumm"
                  value-label-background-color-classes="bg-dnd_yellow"
                  label-classes="font-manrope text-base xl:text-xl"
                  value-label-classes="font-manrope text-base xl:text-xl"
                  data-borders-classes="text-xs xl:text-base font-manrope text-dnd_dark_grey"
                />
                <UiSliderCard
                  v-model="dataForLongStore.step_1.calc_term_value"
                  class="w-full font-semibold"
                  label="Срок займа"
                  type="term"
                  :data="termsValues"
                  :data-borders="[`${termsExtremeValuesTuple[0]}`, `${termsExtremeValuesTuple[1]}`]"
                  :value-label="templateTerm"
                  value-label-background-color-classes="bg-dnd_yellow"
                  label-classes="text-base font-manrope xl:text-xl"
                  value-label-classes="text-base font-manrope xl:text-xl"
                  data-borders-classes="text-xs xl:text-base font-manrope text-dnd_dark_grey"
                />
              </div>
            </div>
            <div
              v-if="!dataForLongStore.isCalcLoading"
              class="flex flex-col gap-y-6 pt-4 lg:gap-y-10 lg:pt-6"
            >
              <div
                class="flex h-[46px] items-center justify-center rounded-lg bg-dnd_light_purple p-4 lg:rounded-[20px] xl:h-[64px] xl:py-5"
              >
                <div class="font-manrope text-xs font-bold xl:text-base">
                  Первый заем бесплатно при погашении за 14 дней
                </div>
              </div>
              <NuxtLink
                to="/vzyat-v-dolg"
                @click="handleMetricButtonClick"
              >
                <UiButton
                  class="!min-h-[56px] w-full !rounded-lg bg-dnd_yellow font-manrope !text-base font-bold lg:!text-lg"
                  small
                >
                  {{ buttonText }}
                </UiButton>
              </NuxtLink>
            </div>
          </div>
        </div>
      </transition>
      <template #fallback>
        <div class="flex h-[300px] w-full items-center justify-center xl:h-[462px]">
          <UiIsLoading />
        </div>
      </template>
    </ClientOnly>
  </div>
</template>

<script setup>
import getPluralNoun from '../helpers/plural';

const { $api, $metrika, $telemetryLog } = useNuxtApp();
const dataForLongStore = useDataForLongStore();

const termTypesPlural = {
  d: ['день', 'дня', 'дней'],
  w: ['неделя', 'недели', 'недель'],
  m: ['месяц', 'месяца', 'месяцев'],
};

const calcData = ref({});
const termsFromValues = ref([]);
const currentSummTermsRange = ref([]);
const termSliderRef = ref(null);
const isContentVisible = ref(false);

const separateNumBySpace = (num) => {
  if (isNaN(Number(num))) return 0;
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const handleMetricButtonClick = () => {
  $telemetryLog({
    event_name: 'btn_get_money_click',
  });
  $metrika.reachGoal('Get_money_button');
};

const parsedTermByNumAndType = computed(() => {
  return dataForLongStore.step_1.calc_term_value?.split('_') ?? ['', ''];
});

const templateRangeSummsTuple = computed(() => {
  if (calcData.value.summs && calcData.value.summs.length > 0) {
    const low = calcData.value.summs?.[0];
    return [
      separateNumBySpace(low),
      separateNumBySpace(calcData.value.summs[calcData.value.summs.length - 1]),
    ];
  }
  return ['', ''];
});

const buttonText = computed(() =>
  dataForLongStore.step_1.product_summ > 50000 ? 'Получить деньги' : 'Получить деньги бесплатно',
);

const templateSumm = computed(() => {
  return (
    dataForLongStore.step_1.product_summ.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ₽'
  );
});

const templateTerm = computed(() => {
  const [number, type] = parsedTermByNumAndType.value;
  if (!number || !type) return '';

  return `${number} ${getPluralNoun(number, ...termTypesPlural[type])}`;
});

const termsValues = computed(() => {
  if (!currentSummTermsRange.value[0] || !calcData.value?.terms) return [];
  return calcData.value?.terms[currentSummTermsRange.value[0]];
});

const termsExtremeValuesTuple = computed(() => {
  if (!termsValues.value.length) return ['', ''];

  const low = termsValues.value[0];
  const high = termsValues.value[termsValues.value.length - 1];
  const [low_num, low_term_type] = low.split('_');
  const [high_num, high_term_type] = high.split('_');

  const lowText = `${low_num} ${getPluralNoun(low_num, ...termTypesPlural[low_term_type])}`;
  const highText = `${high_num} ${getPluralNoun(high_num, ...termTypesPlural[high_term_type])}`;

  return [lowText, highText];
});

const findSummTermsRange = (sum) => {
  const foundIdx = termsFromValues.value.findIndex(
    (el, idx, arr) => el <= sum && arr[idx + 1] > sum,
  );

  return foundIdx > -1
    ? [termsFromValues.value[foundIdx], termsFromValues.value[foundIdx + 1]]
    : [termsFromValues.value[termsFromValues.value.length - 1]];
};

const checkTermSliderProcessForOneValueAndFill = () => {
  if (termsValues.value.length === 1 && termSliderRef.value?.$el) {
    const dot = termSliderRef.value.$el.querySelector('.vue-slider-dot');
    const process = termSliderRef.value.$el.querySelector('.vue-slider-process');
    if (dot && process) {
      dot.style.left = '100%';
      process.style.width = '100%';
    }
  }
};
const { data: asyncData } = await useAsyncData('loanCalculatorData', async () => {
  try {
    dataForLongStore.isCalcLoading = true;
    const { data } = await $api.lkServer().getProductMatrix();
    return data.data;
  } catch (error) {
    throw new Error(error);
  } finally {
    dataForLongStore.isCalcLoading = false;
  }
});

calcData.value = asyncData.value;
termsFromValues.value = Object.keys(calcData.value.terms).sort((a, b) => a - b);

if (!dataForLongStore.step_1.product_summ) {
  dataForLongStore.step_1.product_summ =
    calcData.value.summs[Math.floor((calcData.value.summs.length - 1) / 4)];
}
currentSummTermsRange.value = findSummTermsRange(dataForLongStore.step_1.product_summ);
if (!dataForLongStore.step_1.calc_term_value) {
  if (!dataForLongStore.step_1.product_term) {
    dataForLongStore.step_1.calc_term_value =
      termsValues.value[Math.floor((termsValues.value.length - 1) / 2)];
  } else {
    dataForLongStore.step_1.calc_term_value = `${dataForLongStore.step_1.product_term}_w`;
  }
}

watch(
  () => dataForLongStore.step_1.product_summ,
  (nv) => {
    if (
      (!currentSummTermsRange.value[1] && nv >= currentSummTermsRange.value[0]) ||
      (nv >= currentSummTermsRange.value[0] && nv < currentSummTermsRange.value[1])
    )
      return;

    currentSummTermsRange.value = findSummTermsRange(nv);
    if (!termsValues.value.includes(dataForLongStore.step_1.calc_term_value)) {
      dataForLongStore.step_1.calc_term_value =
        termsValues.value[Math.floor((termsValues.value.length - 1) / 2)];
    }
    checkTermSliderProcessForOneValueAndFill();
  },
);

watch(parsedTermByNumAndType, (nv) => {
  dataForLongStore.step_1.product_term = nv[0];
  dataForLongStore.step_1.product_freq = nv[1];
});

onMounted(() => {
  checkTermSliderProcessForOneValueAndFill();
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active в версиях до 2.1.8 */ {
  opacity: 0;
}
</style>
